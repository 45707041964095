var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("c-tab", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.popupParam.processType == "P",
            expression: "popupParam.processType == 'P'",
          },
        ],
        attrs: {
          type: "tabcard",
          tabItems: _vm.tabItems,
          inlineLabel: true,
          align: "left",
        },
        on: {
          "update:tabItems": function ($event) {
            _vm.tabItems = $event
          },
          "update:tab-items": function ($event) {
            _vm.tabItems = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (tab) {
              return [
                tab.name == "processDetail"
                  ? _c(tab.component, {
                      ref: "processDetail",
                      tag: "component",
                      attrs: { processCd: _vm.popupParam.processCd },
                      on: {
                        "update:processCd": function ($event) {
                          return _vm.$set(_vm.popupParam, "processCd", $event)
                        },
                        "update:process-cd": function ($event) {
                          return _vm.$set(_vm.popupParam, "processCd", $event)
                        },
                      },
                    })
                  : _c(tab.component, {
                      ref: "processUnit",
                      tag: "component",
                      attrs: { processCd: _vm.popupParam.processCd },
                      on: {
                        "update:processCd": function ($event) {
                          return _vm.$set(_vm.popupParam, "processCd", $event)
                        },
                        "update:process-cd": function ($event) {
                          return _vm.$set(_vm.popupParam, "processCd", $event)
                        },
                      },
                    }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.tab,
          callback: function ($$v) {
            _vm.tab = $$v
          },
          expression: "tab",
        },
      }),
      [
        _c(_vm.component, {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.popupParam.processType == "C",
              expression: "popupParam.processType == 'C'",
            },
          ],
          ref: "processUnitDetail",
          tag: "component",
          attrs: { processCd: _vm.popupParam.processCd },
        }),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }